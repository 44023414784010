<template>
    <div style="margin-left: 10px; margin-right: 10px; display: inline-block">
        <el-button type="primary" size="small" @click="openExport">导出</el-button>
        <!--  点击导出按钮的弹窗  -->
        <el-dialog
            title="提示"
            :visible.sync="exportVisible"
            width="25%"
            top="15%"
            z-index="500"
            :close-on-click-modal="false"
            @close="exportForm = {}"
        >
            <div style="margin-top: -6%">每次已生成的报表,系统最多保存7天</div>
            <div style="color: orange">
                为了保证导出成功，尽量避免通过点击右上角"退出登录"按钮退出系统(可以关闭浏览器)。
            </div>
            <div>
                <el-input placeholder="导出说明" v-model="exportForm.taskRemark" maxlength="255" style="margin-top: 5%">
                </el-input>
            </div>
            <div slot="footer" style="margin-top: -5%">
                <el-button @click="openExportTable" size="small">查 看</el-button>
                <el-button type="primary" @click="handleExport" size="small">导 出</el-button>
            </div>
        </el-dialog>

        <!--  查询弹窗  -->
        <el-dialog
            title="导出"
            :visible.sync="tableVisible"
            width="55%"
            top="10%"
            z-index="1000"
            :close-on-click-modal="false"
            @close="resetSearchFrom"
        >
            <el-table
                id="exportTable"
                border
                ref="exportTable"
                stripe
                style="width: 100%"
                :data="tableData"
                v-loading="tableLoading"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="createTime" label="创建时间" width="140" />
                <el-table-column prop="creator" label="创建人" :show-overflow-tooltip="true" width="120" />
                <el-table-column prop="taskRemark" label="导出说明" :show-overflow-tooltip="true" min-width="160" />
                <el-table-column prop="taskState" label="状态" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.taskState | taskStateToDesc }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="failReason" label="失败原因" :show-overflow-tooltip="true" min-width="160" />
                <el-table-column label="操作" width="180" header-align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleDownload(scope.row.fileUrl)"
                            v-if="scope.row.taskState === 2 && scope.row.downloadFlag === true"
                            >下载
                        </el-button>
                        <el-button size="mini" type="danger" @click="handleDelete(scope.row.code)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="searchForm.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="searchForm.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-dialog>
    </div>
</template>
<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'EfExportButton',
    props: {
        url: {
            type: String,
            required: true,
            default: '',
        },
        // 导出按钮所在页面的查询条件
        form: {
            type: Object,
            required: true,
        },
        // 必须选择一条要导出的单据 传true
        requiredSelectOneRow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tableLoading: true,
            exportVisible: false,
            tableVisible: false,
            // 导出按钮提交的参数
            exportForm: {
                taskRemark: '',
            },
            searchForm: {
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            selectedCodes: [],
        };
    },
    mounted() {},
    methods: {
        openExport() {
            // 调用父组件的方法 获取选中的codes
            this.$emit('clickButtonGetSelectedCodes', (selectedCodes) => {
                this.selectedCodes = selectedCodes;
                // 如果传入了requiredSelectOneRow=true,则校验只能选中一行数据
                if (this.requiredSelectOneRow && this.selectedCodes.length !== 1) {
                    this.$message.error('请选择一条要导出的单据');
                } else {
                    this.exportVisible = true;
                }
            });
        },
        handleExport() {
            let queryParam;
            if (this.selectedCodes.length > 0) {
                if (this.requiredSelectOneRow) {
                    queryParam = { code: this.selectedCodes, taskRemark: this.exportForm.taskRemark };
                } else {
                    queryParam = { codes: this.selectedCodes, taskRemark: this.exportForm.taskRemark };
                }
            } else {
                this.form.taskRemark = this.exportForm.taskRemark;
                queryParam = JSON.parse(JSON.stringify(this.form));
                delete queryParam.limit;
                delete queryParam.page;
            }
            this.$http.get(this.url, { params: queryParam }).then((rst) => {
                if (rst.data.status === 200) {
                    this.exportVisible = false;
                    if (typeof rst.data['data'] != 'undefined') {
                        this.$message.success(JSON.parse(JSON.stringify(rst.data['data'] || rst.data['data'])));
                    } else {
                        this.$message.success('操作成功');
                    }
                } else {
                    this.$message.error(rst.data.message);
                }
            });
        },
        openExportTable() {
            this.tableVisible = true;
            this.handleQuery();
        },
        handleQuery() {
            this.searchForm.uri = this.url;
            Util.queryTable(this, '/system/bizExport/pageExtend', { params: this.searchForm });
            this.tableLoading = false;
        },
        resetSearchFrom() {
            this.searchForm.page = 1;
            this.searchForm.limit = Util.Limit;
        },
        handleDownload(fileUrl) {
            window.location.href = fileUrl;
        },
        async handleDelete(code) {
            const _delete = () => {
                return this.$http.delete('/system/bizExport/disable', { params: { code: code } });
            };
            await UrlUtils._commonDml(this, {
                message: '是否删除该信息,删除后不可恢复,是否确认删除?',
                methods: _delete,
            });
            this.handleQuery();
        },
        handleSizeChange(size) {
            this.searchForm.page = 1;
            this.searchForm.limit = size;
            this.handleQuery();
        },
        handleCurrentChange(page) {
            this.searchForm.page = page;
            this.handleQuery();
        },
        handlePrevClick(page) {
            this.searchForm.page = page;
        },
        handleNextClick(page) {
            this.searchForm.page = page;
        },
    },
    filters: {
        taskStateToDesc(taskState) {
            if (typeof taskState == 'undefined') {
                return '';
            }
            if (taskState === 0) {
                return '未开始';
            }
            if (taskState === 1) {
                return '生成中';
            }
            if (taskState === 2) {
                return '已生成';
            }
            if (taskState === 3) {
                return '生成失败';
            }
        },
    },
};
</script>
